<template>
  <div class="bg-black-0 rounded p-3 rounded shadow-sm">
    <b-button
      v-if="mode === 'detail'"
      class="float-right"
      @click="mode = 'edit'"
    >
      Edit</b-button
    >
    <b-button
      v-if="mode === 'edit'"
      class="float-right"
      :disabled="!isFormValid || isLoading || mode === 'detail'"
      :class="{ 'btn-loading': isLoading }"
      variant="primary"
      @click="onSubmit"
    >
      Simpan</b-button
    >
    <b-button
      v-if="mode === 'edit'"
      class="float-right mr-2"
      variant="danger"
      @click="mode = 'detail'"
    >
      Batal</b-button
    >

    <h2>Lab Produksi</h2>
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Kode Kantong Utama" label-for="code_main_bag">
            <b-form-input
              id="code_main_bag"
              v-model="formData.code_main_bag"
              placeholder="Kode kantong utama"
              :disabled="mode === 'detail'"
              required
              @input="debouncedCheckBarcode"
            />
            <p
              :class="{
                'text-success': barcodeValid,
                'text-danger': !barcodeValid,
              }"
            >
              {{ barcodeStatus }}
            </p>
          </b-form-group>

          <b-form-group
            label="Kode Kantong Hasil Produksi"
            label-for="production_result_code"
          >
            <b-form-input
              id="production_result_code"
              v-model="formData.production_result_code"
              placeholder="Kode kantong hasil produksi"
              disabled
              required
            />
          </b-form-group>

          <b-form-group label="Expired" label-for="expired">
            <b-form-input
              id="expired"
              v-model="formData.expired"
              type="date"
              placeholder="Expired"
              disabled
              required
            />
          </b-form-group>

          <b-form-group label="Brand" label-for="brand">
            <b-form-select
              id="brand"
              v-model="brand"
              :options="brandOptions"
              :disabled="mode === 'detail'"
              required
              @change="convertGramToMilli"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group label="Komponen" label-for="component">
            <b-form-select
              id="component"
              v-model="formData.component"
              :options="options.component"
              :disabled="mode === 'detail'"
              required
              @change="updateExpiredDateAndproduction_result_code"
            />
          </b-form-group>

          <b-form-group label="Status" label-for="status">
            <b-form-radio-group
              id="status"
              v-model="formData.status"
              :options="[
                { text: 'Berhasil', value: 'passed' },
                { text: 'Gagal', value: 'failed' },
              ]"
              name="status-options"
              :disabled="mode === 'detail'"
              required
            />
          </b-form-group>

          <b-form-group
            v-if="formData.status === 'failed'"
            label="Alasan Gagal"
            label-for="reason_fail"
          >
            <v-select
              id="reason_fail"
              v-model="formData.reason_failed"
              :options="options.reason_failed"
              placeholder="Pilih Alasan Gagal"
              :disabled="mode === 'detail'"
            />
          </b-form-group>

          <b-form-group label="Gram" label-for="gram">
            <b-form-input
              id="gram"
              v-model="formData.gram"
              type="number"
              placeholder="Gram"
              :disabled="mode === 'detail'"
              required
              @input="convertGramToMilli"
            />
          </b-form-group>

          <b-form-group label="Mili" label-for="mili">
            <b-form-input
              id="mili"
              v-model="formData.cc"
              type="number"
              placeholder="mili"
              :disabled="mode === 'detail'"
              required
            />
          </b-form-group>
        </b-col>
      </b-row>

      <div
        v-if="mode === 'add'"
        class="d-flex justify-content-end mt-3"
        style="gap: 1rem"
      >
        <b-button
          :disabled="!isFormValid || isLoading || mode === 'detail'"
          :variant="isLoading ? 'outline-primary' : 'primary-2'"
          :class="{ 'btn-loading': isLoading }"
          @click="onSubmit"
        >
          <b-spinner v-if="isLoading" small />
          Simpan
        </b-button>
        <b-button
          :disabled="!isFormValid || isLoading || mode === 'detail'"
          :variant="isLoading ? 'outline-warning-2' : 'warning-2'"
          :class="{ 'btn-loading': isLoading }"
          to="/dashboards/print-labproduction"
        >
          <b-spinner v-if="isLoading" small />
          Simpan & Cetak
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormRadioGroup,
  BSpinner,
} from "bootstrap-vue";
import labproductionAPI from "../../../../api/labProduction/labproductionAPI";

export default {
  components: {
    vSelect,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormRadioGroup,
    BSpinner,
  },

  data() {
    return {
      isLoading: false,
      barcodeStatus: "",
      barcodeValid: false,
      mode: "",
      brand: "",
      formData: {
        code_main_bag: "",
        production_result_code: "",
        expired: "",
        component: "",
        status: "",
        reason_failed: "",
        gram: "",
        cc: "",
      },
      options: {
        component: [
          { value: "", text: "Pilih component" },
          { value: "AHF", text: "Anti Hemofili Factor (AHF)/Cryo" },
          { value: "FFP", text: "Fresh Frozen Plasma (FFP)" },
          { value: "LP", text: "Liquid Plasma (LP)" },
          { value: "PRC", text: "Packed Red Cell (PRC)" },
          { value: "PCL-PRC-BAYI", text: "PCL/Packed Red Cell untuk Bayi" },
          { value: "TC", text: "Trombocyte Consentrat (TC)" },
          { value: "WB", text: "Whole Blood (WB)" },
        ],
        reason_failed: [
          "Bocor",
          "Darah Kembali",
          "Darah Tidak Diambil",
          "Gagal/Mistake",
          "HBsAG+",
          "HCV+",
          "HIV+",
          "Hemolyse",
          "Hijau",
          "Keruh",
          "Kuning",
          "Merah",
          "Od",
          "Pecah waktu diputar",
          "Stolsel",
          "VDRL+",
        ],
      },
      expirationPeriods: {
        ahf: 365,
        ffp: 365,
        lp: 40,
        prc: 35,
        "pcl-prc-bayi": 35,
        tc: 5,
        wb: 35,
      },
      componentAbbreviations: {
        ahf: "AHF",
        ffp: "FFP",
        lp: "LP",
        prc: "PRC",
        "pcl-prc-bayi": "PCL-PRC-Bayi",
        tc: "TC",
        wb: "WB",
      },
      brandOptions: [
        { value: "Terumo", text: "Terumo" },
        { value: "Hemopack", text: "Hemopack" },
      ],
      componentDensities: {
        PRC: 1.095,
        TC: 1.032,
        WB: 1.055,
        LP: 1.027,
        AHF: 1.095,
        FFP: 1.095,
        "PCL-PRC-BAYI": 1.095,
      },
    };
  },

  computed: {
    isFormValid() {
      const {
        code_main_bag,
        production_result_code,
        expired,
        component,
        status,
        gram,
        cc,
      } = this.formData;
      return (
        code_main_bag &&
        production_result_code &&
        expired &&
        component &&
        status &&
        gram &&
        cc &&
        this.barcodeValid
      );
    },
  },

  watch: {
    "formData.component": function (newValue, oldValue) {
      this.updateExpiredDateAndproduction_result_code();
      this.convertGramToMilli();
    },
    "formData.code_main_bag": "updateExpiredDateAndproduction_result_code",
    "formData.gram": "convertGramToMilli",
    "formData.brand": "convertGramToMilli",
  },

  mounted() {
    this.debouncedCheckBarcode = debounce(this.checkBarcode, 500);
    if (this.$route.params.id) {
      this.mode = "detail";
      this.getDetailLabProduction();
    } else {
      this.mode = "add";
    }
  },

  methods: {
    updateExpiredDateAndproduction_result_code() {
      if (this.mode === "detail") return;

      const { component, code_main_bag } = this.formData;
      const daysToAdd = this.expirationPeriods[component.toLowerCase()] || 0;

      if (daysToAdd) {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + daysToAdd);
        this.formData.expired = expirationDate.toISOString().split("T")[0];
      } else {
        this.formData.expired = "";
      }

      this.formData.production_result_code = `${code_main_bag}${
        this.componentAbbreviations[component.toLowerCase()] || ""
      }`;
    },

    async checkBarcode() {
      if (!this.formData.code_main_bag) {
        this.barcodeStatus = "";
        this.barcodeValid = false;
        return;
      }

      try {
        const { data: response } = await labproductionAPI.checkBarcode({
          barcode: this.formData.code_main_bag,
        });
        if (response.success) {
          this.barcodeStatus = response.message;
          this.barcodeValid = true;
        } else {
          if (response.message === "kantong darah terdeteksi reaktif◾") {
            this.barcodeStatus = "Kantong darah terdeteksi reaktif ◾";
          } else {
            this.barcodeStatus = "Kantong darah tidak ditemukan";
          }
          this.barcodeValid = false;
        }
      } catch (error) {
        console.error("API error:", error);
        this.barcodeStatus = "Kantong Darah tidak ditemukan";
        this.barcodeValid = false;
      }
    },

    async getDetailLabProduction() {
      this.isLoading = true;
      try {
        const { data: response } = await labproductionAPI.Detail(
          this.$route.params.id
        );
        this.formData = response.data;
        this.barcodeStatus = "Kantong darah ditemukan";
        this.barcodeValid = true;
      } catch (error) {
        console.error("Failed to get lab production detail:", error);
      } finally {
        this.isLoading = false;
      }
    },

    convertGramToMilli() {
      if (this.formData.gram && this.brand && this.formData.component) {
        const weightBloodBag = parseFloat(this.formData.gram);
        const weightBagByBrand = this.brand === "Terumo" ? 35 : 38;
        const weightTypeBag = this.componentDensities[this.formData.component];
        const volume = (weightBloodBag - weightBagByBrand) / weightTypeBag;
        this.formData.cc = volume.toFixed(1);
      } else {
        this.formData.cc = null;
      }
    },

    async onSubmit() {
      this.isLoading = true;
      try {
        if (this.formData.status === "passed") {
          this.formData.reason_failed = "";
        }
        if (this.mode === "add") {
          await labproductionAPI.add(this.formData);
          this.$bvToast.toast("Data berhasil ditambahkan", {
            title: "Success!",
            autoHideDelay: 5000,
            variant: "success",
          });
          this.$router.push("/dashboards/labproductions");
        } else {
          await labproductionAPI.update(this.formData);
          this.$bvToast.toast("Data berhasil diupdate", {
            title: "Success!",
            autoHideDelay: 5000,
            variant: "success",
          });
          this.mode = "detail";
        }
      } catch (error) {
        console.error("Failed to add lab production:", error);
        this.$bvToast.toast("Gagal menyimpan data", {
          title: "Error!",
          autoHideDelay: 5000,
          variant: "danger",
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
